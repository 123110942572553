import Box from '@mui/material/Box';
import {styled} from '@mui/material/styles';
import React from 'react';
import {useHistory} from 'react-router-dom';
import {useInitiateOffer, useInitiateProposal} from 'v2/common/api/queries/premiumCalculation';
import {useCloseAppDrawer} from 'v2/features/app-drawer/hooks';
import {
   InitiateSalesProcessFormValues,
   SalesProcessType,
} from 'v2/features/PremiumCalculation/components/InitiateSalesProcess/InitiateSalesProcess';
import PremiumCalculation from 'v2/features/PremiumCalculation/components/PremiumCalculation';

const ScrollableBox = styled(Box)(({theme}) => ({
   maxHeight: '100%',
   overflowY: 'auto',
   '&::-webkit-scrollbar': {
      width: 5,
   },
   '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.main,
      borderRadius: 10,
   },
}));

const SidePanelPremiumCalculation = () => {
   const history = useHistory();
   const closeDrawer = useCloseAppDrawer();
   const {mutateAsync: initiateProposal, isLoading: isProposalLoading} = useInitiateProposal();
   const {mutateAsync: initiateOffer, isLoading: isOfferLoading} = useInitiateOffer();

   const salesProcessInitiateHandler = async (
      type: SalesProcessType,
      values: InitiateSalesProcessFormValues
   ) => {
      if (type === SalesProcessType.Offer) {
         await initiateOffer({
            values,
            onSuccess: (id: string) => {
               history.push(`/sales-process/offer/${id}`);
               closeDrawer();
            },
         });
      } else {
         await initiateProposal({
            values,
            onSuccess: (id: string) => {
               history.push(`/sales-process/proposals/${id}`);
               closeDrawer();
            },
         });
      }
   };

   return (
      <ScrollableBox paddingX={2} data-testid="premium-calculation-component">
         <PremiumCalculation
            onSalesProcessInitiate={salesProcessInitiateHandler}
            isOfferLoading={isOfferLoading}
            isProposalLoading={isProposalLoading}
         />
      </ScrollableBox>
   );
};

export default SidePanelPremiumCalculation;
