import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded';
import LoadingButton from '@mui/lab/LoadingButton';
import {formHelperTextClasses} from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import React from 'react';
import {Controller, useFieldArray, useForm} from 'react-hook-form';
import {FormattedMessage, useIntl} from 'react-intl';
import {toastHandler} from 'shared/services';
import {isInRangeOfOneTimeOnly} from 'shared/services/utils';
import {
  PaymentFrequency,
  PremiumCalculationInsuredParams,
  PremiumCalculationInsuredParamsOldFormat,
  usePremiumCalculation,
} from 'v2/common/api/queries/premiumCalculation';
import DropdownField from 'v2/common/components/DropdownField';
import Label from 'v2/common/components/Label';
import {transformPremiumCalculationFormValues} from 'v2/common/utils/apiTransformations';
import {useOpenDrawerPanel} from 'v2/features/app-drawer/hooks';
import {AppDrawerPanel} from 'v2/features/app-drawer/types';
import InitiateSalesProcess from 'v2/features/PremiumCalculation/components/InitiateSalesProcess';
import {
  InitiateSalesProcessFormValues,
  SalesProcessType,
} from 'v2/features/PremiumCalculation/components/InitiateSalesProcess/InitiateSalesProcess';
import InsuredPremiumFields from 'v2/features/PremiumCalculation/components/InsuredPremiumFields';
import PremiumDetailsResult from 'v2/features/PremiumCalculation/components/PremiumDetailsResult';
import {
  usePaymentFrequencies,
  usePrefilledPremiumParams,
} from 'v2/features/PremiumCalculation/hooks';
import {premiumCalculationSchema} from 'v2/features/PremiumCalculation/schema';

export type PremiumCalculationFormValues = {
  insuredAdults: PremiumCalculationInsuredParams[];
  paymentFrequency: PaymentFrequency;
};

// TODO Should be removed in https://delanet.atlassian.net/browse/BROKP-8873
export type PremiumCalculationFormValuesOldFormat = {
  insuredAdults: PremiumCalculationInsuredParamsOldFormat[];
  paymentFrequency: PaymentFrequency;
};

type PremiumCalculationProps = {
  onSalesProcessInitiate: (
    type: SalesProcessType,
    values: InitiateSalesProcessFormValues
  ) => Promise<void>;
  isOfferLoading?: boolean;
  isProposalLoading?: boolean;
};

const BlankInsuredParams: PremiumCalculationInsuredParams = {
  benefit: {amount: 0},
  personalInformation: {birthDate: ''},
  payUntilAge: 0,
};

const PremiumCalculation = ({
                              onSalesProcessInitiate,
                              isOfferLoading,
                              isProposalLoading,
                            }: PremiumCalculationProps) => {
  const intl = useIntl();
  const {
    variables: premiumParams,
    mutate: calculatePremium,
    data: premiumResult,
    isLoading,
    reset: resetPremiumCalculationResult,
  } = usePremiumCalculation();

  const {control, handleSubmit, reset: resetPremiumForm, setValue, watch, trigger} = useForm<
    PremiumCalculationFormValues
  >({
    defaultValues: {
      paymentFrequency: PaymentFrequency.Monthly,
      insuredAdults: [BlankInsuredParams],
    },
    mode: 'onChange',
    resolver: yupResolver(premiumCalculationSchema),
    shouldFocusError: true,
  });
  const {fields: fieldArray, append, remove} = useFieldArray<PremiumCalculationFormValues>({
    control,
    name: 'insuredAdults',
  });

  const openPanel = useOpenDrawerPanel();
  const params = usePrefilledPremiumParams();
  React.useEffect(() => {
    if (params) {
      openPanel(AppDrawerPanel.CalculatePremium);
      resetPremiumForm(params);
    }
  }, [openPanel, params, resetPremiumForm]);

  const [firstInsuredBirthDate, paymentFrequency] = watch([
    'insuredAdults.0.personalInformation.birthDate',
    'paymentFrequency',
  ]);

  const isOneAdult = fieldArray.length === 1;
  const isFirstAdultInRangeOfOneTimeOnly = isInRangeOfOneTimeOnly(firstInsuredBirthDate);
  const paymentFrequencies = usePaymentFrequencies(isOneAdult, isFirstAdultInRangeOfOneTimeOnly);
  const isOneTimeOnlySelected = paymentFrequency === PaymentFrequency.OneTimeOnly;

  React.useEffect(() => {
    if (isOneAdult && isFirstAdultInRangeOfOneTimeOnly) {
      setValue('paymentFrequency', PaymentFrequency.OneTimeOnly);
      trigger(['insuredAdults.0.personalInformation.birthDate', 'insuredAdults.0.payUntilAge']);
    }
  }, [isFirstAdultInRangeOfOneTimeOnly, isOneAdult, setValue, trigger]);

  const AddInsured = () => {
    resetPremiumCalculationResult();
    append(BlankInsuredParams);
  };

  const removeInsured = (index: number) => {
    resetPremiumCalculationResult();
    remove(index);
  };

  const onSubmit = (values: PremiumCalculationFormValues) =>
    calculatePremium(transformPremiumCalculationFormValues(values), {
      onError: toastHandler.unexpectedIssue,
    });

  const resetCalculationForm = () => {
    toastHandler.closeAll();
    resetPremiumCalculationResult();
    resetPremiumForm();
  };

  return (
    <>
      <form
        autoComplete="off"
        onChange={resetPremiumCalculationResult}
        onReset={resetCalculationForm}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid
          container
          columnSpacing={2}
          sx={{[`& .${formHelperTextClasses.root}`]: {minHeight: '42px', lineHeight: 1.1}}}
        >
          {fieldArray.map((field, index) => (
            <React.Fragment key={field.id}>
              <InsuredPremiumFields
                index={index}
                isOneAdult={isOneAdult}
                paymentFrequency={paymentFrequency}
                control={control}
                removeInsured={removeInsured}
              />
              {isOneAdult && !isOneTimeOnlySelected ? (
                <Grid item xs={6} alignSelf="center" textAlign="center">
                  <Button onClick={AddInsured} data-testid={"add-insured-adult-button"}>
                    <PersonAddAltRoundedIcon
                      sx={{fontSize: 80}}
                      titleAccess={intl.formatMessage({id: 'misc.addAdult'})}
                    />
                  </Button>
                </Grid>
              ) : null}
            </React.Fragment>
          ))}
        </Grid>
        <Grid container columnSpacing={2}>
          <Grid item xs={6} sx={{marginBottom: 2}}>
            <Controller
              name="paymentFrequency"
              control={control}
              rules={{
                deps: [
                  'insuredAdults.0.personalInformation.birthDate',
                  'insuredAdults.0.payUntilAge',
                ],
              }}
              render={({field, fieldState}) => (
                <DropdownField
                  {...field}
                  data-testid="frequency-of-payment-dropdown"
                  error={fieldState.invalid}
                  label={
                    <Label
                      label={<FormattedMessage id="fields.paymentFrequency.label"/>}
                      hint={
                        <FormattedMessage
                          id="fields.paymentFrequency.hint"
                          values={{br: <br/>}}
                        />
                      }
                    />
                  }
                  options={paymentFrequencies}
                  onChange={(event) => {
                    field.onChange(event.target.value);
                    resetPremiumCalculationResult();
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}/>
          <Grid item xs={6}>
            <LoadingButton
              variant="contained"
              color="secondary"
              fullWidth
              type="submit"
              loading={isLoading}
              data-testid='calculate-premium-button'
            >
              <FormattedMessage id="misc.calculatePremium"/>
            </LoadingButton>
          </Grid>
          <Grid item xs={6}>
            <Button fullWidth variant="outlined" type="reset">
              <FormattedMessage id="calculatePremium.restartCalculate"/>
            </Button>
          </Grid>
        </Grid>
      </form>
      {premiumResult && premiumParams ? (
        <>
          <PremiumDetailsResult
            results={premiumResult.calculationResult}
            paymentFrequency={paymentFrequency}
            isOneAdult={isOneAdult}
          />
          <InitiateSalesProcess
            premiumValues={premiumParams}
            isOneAdult={isOneAdult}
            onSuccess={resetCalculationForm}
            onSalesProcessInitiate={onSalesProcessInitiate}
            isOfferLoading={isOfferLoading}
            isProposalLoading={isProposalLoading}
          />
        </>
      ) : null}
    </>
  );
};

export default PremiumCalculation;
