import React, {Component} from 'react';
import {components} from 'react-select';
import {Field} from 'redux-form';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import {PHONE_CODES, phoneValueToCountry} from '../../constants';

import belgiumIcon from '../../../assets/images/Belgium.svg';
import franceIcon from '../../../assets/images/France.svg';
import germanyIcon from '../../../assets/images/Germany.svg';
import luxembourgIcon from '../../../assets/images/Luxembourg.svg';
import netherlandsIcon from '../../../assets/images/Netherlands.svg';

import './styles.scss';
import DropdownControl from '../reduxFormField/Dropdown';
import TextInputControl from '../reduxFormField/TextInput';
import {FormattedMessage} from '../FormattedMessage';
import FieldWithServerError from '../reduxFormField/FieldWithServerError';

export const phoneValueToFlagIcon = {
   [PHONE_CODES.BELGIUM]: belgiumIcon,
   [PHONE_CODES.FRANCE]: franceIcon,
   [PHONE_CODES.GERMANY]: germanyIcon,
   [PHONE_CODES.LUXEMBOURG]: luxembourgIcon,
   [PHONE_CODES.NETHERLANDS]: netherlandsIcon,
};

export class PhoneNumberField extends Component {
   maskPhone = (value) => {
      const {codeValue, mask} = this.props;
      if (!mask) {
         return;
      }
      return mask(codeValue, value);
   };

   render() {
      const {
         labelId,
         serverError,
         disabled,
         phoneNumberError,
         name,
         phoneCodes,
         isNotificationShown,
         dataTestIdPrefix,
         ...restProps
      } = this.props;

      const codeFieldName = `${name}Code`;
      const fieldProps = {
         name,
         disabled,
         component: TextInputControl,
         dataTestIdPrefix,
         ...restProps,
         maxLength: 16,
         mask: this.maskPhone,
         metaHeightAuto: isNotificationShown,
         prefix: (
            <Field
               name={codeFieldName}
               className="phone-field-dropdown"
               options={phoneCodes}
               onChange={this.changeCode}
               components={{
                  IndicatorSeparator: null,
                  Option: this.optionRenderer,
               }}
               disabled={disabled}
               component={DropdownControl}
               dataTestIdPrefix={`${dataTestIdPrefix}-country-code`}
               hideMeta
            />
         ),
      };

      return (
         <>
            <span className="phone-field-label">
               <FormattedMessage id={labelId} />
            </span>
            <div className="phone-field">
               {serverError ? (
                  <FieldWithServerError serverError={serverError} {...fieldProps} />
               ) : (
                  <Field {...fieldProps} />
               )}
            </div>
         </>
      );
   }

   changeCode = (codeValue) => {
      const {change, name} = this.props;
      const codeFieldName = `${name}Code`;

      change(codeFieldName, codeValue);
      change(name, null);
   };

   optionRenderer = (props) => {
      const {value} = props;
      const {intl} = this.props;

      const countryLabel = intl.formatMessage({
         id: `references.countries.${phoneValueToCountry[value]}`,
      });

      return (
         <components.Option {...props}>
            <>
               <img src={phoneValueToFlagIcon[value]} alt={countryLabel} />
               <span className="dropdown-input__option-name">{countryLabel}</span>
               <span className="dropdown-input__option-code">{value}</span>
            </>
         </components.Option>
      );
   };
}

PhoneNumberField.propTypes = {
   labelId: PropTypes.string.isRequired,
   name: PropTypes.string.isRequired,
   phoneCodes: PropTypes.arrayOf(
      PropTypes.shape({
         value: PropTypes.string.isRequired,
         label: PropTypes.string.isRequired,
      })
   ).isRequired,
   change: PropTypes.func.isRequired,
   serverError: PropTypes.shape({path: PropTypes.string, messageProps: PropTypes.object}),
   disabled: PropTypes.bool,
   mask: PropTypes.func,
   codeValue: PropTypes.string,
   phoneNumberError: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
   isNotificationShown: PropTypes.bool,
};

export default injectIntl(PhoneNumberField);
