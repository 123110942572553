import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {Field} from 'redux-form';
import {useIntl} from 'react-intl';
import {FormattedMessage} from '../../../shared/components/FormattedMessage';
import InfoPanel from '../../../shared/components/InfoPanel';
import * as fields from '../../../shared/constants/fields';
import EmailField from '../../../shared/components/EmailField';
import RadioButtonGroupControl from '../reduxFormField/RadioButtonGroup';
import TextInputControl from '../reduxFormField/TextInput';
import HiddenInput from '../reduxFormField/HiddenInput';
import FieldWithServerError from '../reduxFormField/FieldWithServerError';
import {filterNumbers, parseNumber} from '../../services/utils';
import {
   isOldForOneTimePayment,
   isOldForPeriodicPayment,
   nationalRegisterNumberFormat,
   nationalRegisterNumberMatchesInsuredAdult,
   nationalRegisterNumberUniqueFirstInsuredAdult,
   nationalRegisterNumberUniqueSecondInsuredAdult,
} from '../../services/validators';
import PersonalEmailInfo from '../EmailField/PersonalEmailInfo';
import PhoneNumberField from '../PhoneNumberField';
import './styles.scss';

const insuredNationalRegisterNumberValidate = [
   [nationalRegisterNumberFormat, nationalRegisterNumberMatchesInsuredAdult(0)],
   [nationalRegisterNumberFormat, nationalRegisterNumberMatchesInsuredAdult(1)],
];

const InsuredAdultFields = (props) => {
   const {
      index,
      isOneAdult,
      isOneTimeOnly,
      disabled = false,
      remove,
      change,
      touch,
      genders = [],
      onCalculationDataChange,
      onPersonalInfoChange,
      onRemoveInsuredAdult,
      omitInsuredAdultServerErrors = noop,
      showBirthDateInfo,
      phoneCodes,
      mobilePhoneNumberError,
      mobileNumberCode,
      isEmailInfoShown,
      isNotificationShown,
      extended = false,
      beneficiaries,
      titleError,
      changePolicyHolderName,
      isInsuredEmailRequired,
      participatingAsPolicyHolder,
      policyHolderNationalRegisterNumber,
      showNationalRegisterNumberInfo
   } = props;
   const intl = useIntl();
   const insuredFirstNameValidator = useMemo(
      () => fields.insuredFirstName(beneficiaries).validate[index],
      [beneficiaries, index]
   );
   const insuredSurnameValidator = useMemo(
      () => fields.insuredSurname(beneficiaries).validate[index],
      [beneficiaries, index]
   );
   const insuredBirthDateValidator = useMemo(
      () => fields.insuredBirthDate(beneficiaries).validate[index],
      [beneficiaries, index]
   );

   const nationalRegisterUniqueValidator = [
      useMemo(
         () =>
            nationalRegisterNumberUniqueFirstInsuredAdult(
               policyHolderNationalRegisterNumber,
               participatingAsPolicyHolder
            ),
         [policyHolderNationalRegisterNumber, participatingAsPolicyHolder]
      ),
      useMemo(
         () => nationalRegisterNumberUniqueSecondInsuredAdult(policyHolderNationalRegisterNumber),
         [policyHolderNationalRegisterNumber]
      ),
   ];

   const touchSurname = () => {
      touch(`insuredAdults[0].personalInformation.surname`);
      touch(`insuredAdults[1].personalInformation.surname`);
   };

   const changeSurname = (e) => {
      if (changePolicyHolderName) {
         changePolicyHolderName('surname', e.target.value);
      }
   };

   const touchFirstName = () => {
      touch(`insuredAdults[0].personalInformation.firstName`);
      touch(`insuredAdults[1].personalInformation.firstName`);
   };

   const changeFirstName = (e) => {
      if (changePolicyHolderName) {
         changePolicyHolderName('firstName', e.target.value);
      }
   };

   const touchBenefits = () => {
      touch(['insuredAdults[0].benefit.amount', 'insuredAdults[1].benefit.amount']);
   };
   const touchBirthDates = () => {
      touch(`insuredAdults[0].personalInformation.birthDate`);
      touch(`insuredAdults[1].personalInformation.birthDate`);
   };

   const touchName = () => {
      touchSurname();
      touchFirstName();
   };
   const touchPayUntilAge = () => touch(`insuredAdults[${index}].payUntilAge`);

   const touchNationalRegisterNumber = () =>
      touch(`insuredAdults[${index}].personalInformation.nationalRegisterNumber`);

   const touchNationalRegisterNumberFields = () => {
      touch(`insuredAdults[0].personalInformation.nationalRegisterNumber`);
      touch(`insuredAdults[1].personalInformation.nationalRegisterNumber`);
   };

   const handleRemove = () => {
      onRemoveInsuredAdult(index, () => {
         change('careClause', false);
         setTimeout(() => {
            remove('insuredAdults', index);
            omitInsuredAdultServerErrors(index);
            onCalculationDataChange();
         });
      });
   };

   const insuredBirthDate = isOneTimeOnly
      ? [...insuredBirthDateValidator, isOldForOneTimePayment]
      : [...insuredBirthDateValidator, isOldForPeriodicPayment];
   return (
      <div className="insured-adults">
         <h3 className="title-insured">
            <FormattedMessage id="misc.insuredAdult" /> {!isOneAdult && index + 1}
         </h3>
         {!disabled && !isOneAdult && (
            <button type="button" className="btn-remove" onClick={handleRemove}>
               <i className="iconfont icon-cross" />
            </button>
         )}
         {titleError && (
            <div className="title-error">
               <FormattedMessage id={titleError} />
            </div>
         )}
         <Field name={`insuredAdults[${index}].id`} component={HiddenInput} />
         <FieldWithServerError
            name={`insuredAdults[${index}].personalInformation.firstName`}
            label={intl.formatMessage({
               id: 'fields.firstName.label',
            })}
            maxLength={50}
            disabled={disabled}
            component={TextInputControl}
            placeholder={intl.formatMessage({
               id: 'fields.firstName.placeholder',
            })}
            validate={insuredFirstNameValidator}
            onBlur={() => {
               touchName();
               touchBirthDates();
               onPersonalInfoChange(index);
            }}
            onChange={changeFirstName}
            serverError={{
               path: `insuredAdults[${index}].personalInformation.firstName`,
            }}
            dataTestIdPrefix={"first-name"}
         />
         <FieldWithServerError
            name={`insuredAdults[${index}].personalInformation.surname`}
            label={intl.formatMessage({
               id: 'fields.surname.label',
            })}
            maxLength={50}
            disabled={disabled}
            component={TextInputControl}
            placeholder={intl.formatMessage({
               id: 'fields.surname.placeholder',
            })}
            validate={insuredSurnameValidator}
            onChange={changeSurname}
            onBlur={() => {
               touchName();
               touchBirthDates();
               onPersonalInfoChange(index);
            }}
            serverError={{
               path: `insuredAdults[${index}].personalInformation.surname`,
            }}
            dataTestIdPrefix={"surname"}
         />
         {extended && (
            <FieldWithServerError
               name={`insuredAdults[${index}].personalInformation.gender`}
               label={intl.formatMessage({
                  id: 'fields.gender.label',
               })}
               disabled={disabled}
               component={RadioButtonGroupControl}
               options={genders}
               onChange={touchNationalRegisterNumber}
               serverError={{
                  path: `insuredAdults[${index}].personalInformation.gender`,
               }}
               dataTestIdPrefix={"gender"}
            />
         )}
         <FieldWithServerError
            name={`insuredAdults[${index}].personalInformation.birthDate`}
            label={intl.formatMessage({
               id: 'fields.birthDate.label',
            })}
            required
            disabled={disabled || showNationalRegisterNumberInfo}
            component={TextInputControl}
            mask={fields.date.mask}
            placeholder={intl.formatMessage({
               id: 'fields.birthDate.placeholder',
            })}
            validate={insuredBirthDate}
            onBlur={() => {
               touchName();
               touchBirthDates();
               touchPayUntilAge();
               touchNationalRegisterNumber();
               onCalculationDataChange();
            }}
            metaHeightAuto={showBirthDateInfo}
            serverError={{
               path: `insuredAdults[${index}].personalInformation.birthDate`,
            }}
            info={showNationalRegisterNumberInfo && <FormattedMessage id="fields.birthDate.nationalRegisterNumberInfo" />}
            dataTestIdPrefix={"date-of-birth"}
         />
         {showBirthDateInfo && (
            <InfoPanel>
               <FormattedMessage id="fields.birthDate.info" />
            </InfoPanel>
         )}
         {extended && (
            <>
               <FieldWithServerError
                  name={`insuredAdults[${index}].personalInformation.nationalRegisterNumber`}
                  label={intl.formatMessage({
                     id: 'fields.nationalRegisterNumber.label',
                  })}
                  disabled={disabled}
                  component={TextInputControl}
                  placeholder={intl.formatMessage({
                     id: 'fields.nationalRegisterNumber.placeholder',
                  })}
                  {...fields.nationalRegisterNumber}
                  validate={[
                     ...insuredNationalRegisterNumberValidate[index],
                     nationalRegisterUniqueValidator[index],
                  ]}
                  onBlur={touchName}
                  onChange={touchNationalRegisterNumberFields}
                  serverError={{
                     path: `insuredAdults[${index}].personalInformation.nationalRegisterNumber`,
                  }}
                  dataTestIdPrefix={"national-registration-number"}
               />
               <EmailField
                  name={`insuredAdults[${index}].email`}
                  label={intl.formatMessage({
                     id: 'fields.email.label',
                  })}
                  hint={intl.formatMessage({id: 'fields.email.hint'}, {br: <br />})}
                  disabled={disabled}
                  validate={
                     isInsuredEmailRequired ? fields.email.validate : fields.insuredEmail.validate
                  }
                  onBlur={touchName}
                  metaHeightAuto={isEmailInfoShown}
                  dataTestIdPrefix={"email"}
               />
               {isEmailInfoShown && <PersonalEmailInfo />}
            </>
         )}
         {extended && (
            <PhoneNumberField
               name={`insuredAdults[${index}].mobileNumber`}
               labelId="fields.smsMobileNumber.label"
               disabled={disabled}
               change={change}
               phoneCodes={phoneCodes}
               phoneNumberError={mobilePhoneNumberError}
               normalize={fields.insuredMobilePhoneNumber.normalize}
               mask={fields.insuredMobilePhoneNumber.mask}
               validate={fields.insuredMobilePhoneNumber.validate[index]}
               codeValue={mobileNumberCode}
               onBlur={touchName}
               isNotificationShown={isNotificationShown}
            />
         )}
         {isNotificationShown && (
            <InfoPanel highlight>
               <FormattedMessage id="fields.smsMobileNumber.mobileSigningDeprecated" />
            </InfoPanel>
         )}
         <Field
            name={`insuredAdults[${index}].payUntilAge`}
            label={intl.formatMessage({
               id: 'fields.payUntilAge.label',
            })}
            required
            placeholder={intl.formatMessage({
               id: 'fields.payUntilAge.placeholder',
            })}
            mask={fields.payUntilAge.mask}
            disabled={isOneTimeOnly || disabled}
            info={
               isOneTimeOnly && (
                  <span>
                     <FormattedMessage id="fields.payUntilAge.info" />
                  </span>
               )
            }
            component={TextInputControl}
            parse={parseNumber}
            validate={isOneTimeOnly ? undefined : fields.payUntilAge.validate[index]}
            onBlur={onCalculationDataChange}
            dataTestIdPrefix={"pay-until-age"}
         />
         <Field
            name={`insuredAdults[${index}].benefit.amount`}
            label={intl.formatMessage({
               id: 'fields.benefit.label',
            })}
            required
            disabled={disabled}
            placeholder={fields.amount.placeholder}
            hint={
               <div style={{maxWidth: '195px'}}>
                  {intl.formatMessage(
                     {id: 'fields.benefit.hint'},
                     {min: fields.benefitLowerRangeValue}
                  )}
               </div>
            }
            component={TextInputControl}
            mask={fields.amount.mask}
            parse={filterNumbers}
            validate={fields.benefit.validate}
            onBlur={() => {
               touchBenefits();
               onCalculationDataChange();
            }}
            dataTestIdPrefix={"benefit"}
         />
      </div>
   );
};
InsuredAdultFields.propTypes = {
   index: PropTypes.number.isRequired,
   isOneAdult: PropTypes.bool.isRequired,
   isOneTimeOnly: PropTypes.bool.isRequired,
   disabled: PropTypes.bool,
   remove: PropTypes.func.isRequired,
   change: PropTypes.func.isRequired,
   touch: PropTypes.func.isRequired,
   genders: PropTypes.array,
   onCalculationDataChange: PropTypes.func.isRequired,
   onPersonalInfoChange: PropTypes.func.isRequired,
   onRemoveInsuredAdult: PropTypes.func.isRequired,
   omitInsuredAdultServerErrors: PropTypes.func,
   showBirthDateInfo: PropTypes.bool,
   phoneCodes: PropTypes.arrayOf(
      PropTypes.shape({
         value: PropTypes.string.isRequired,
         label: PropTypes.string.isRequired,
      })
   ),
   mobilePhoneNumberError: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.shape({
         id: PropTypes.string,
      }),
   ]),
   isNotificationShown: PropTypes.bool,
   isInsuredEmailRequired: PropTypes.bool,
   isEmailInfoShown: PropTypes.bool,
   titleError: PropTypes.string,
   extended: PropTypes.bool,
};

export default InsuredAdultFields;
