import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { bool, func } from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import AsyncComponent from '../../../shared/components/AsyncComponent';
import InfoPanel from '../../../shared/components/InfoPanel';
import DropdownControl from '../../../shared/components/reduxFormField/Dropdown';
import FieldWithServerError from '../../../shared/components/reduxFormField/FieldWithServerError';
import Label from '../../../shared/components/reduxFormField/Label';
import RadioButtonGroupControl from '../../../shared/components/reduxFormField/RadioButtonGroup';
import TextArea from '../../../shared/components/reduxFormField/TextArea';
import TextInputControl from '../../../shared/components/reduxFormField/TextInput';
import { SIMPLE_ANSWER } from '../../../shared/constants';
import { additionalInformation } from '../../../shared/constants/fields';
import {
  insuranceIntroducerOptionsShape,
  yesNoOptionsShape,
} from '../../../shared/constants/propTypes';
import { makeSelectIsYes } from '../../../shared/reducers/selectors';
import { selectInsuranceIntroducer, selectYesNo } from '../../../shared/references/selectors';
import { scrollToInvalidInput } from '../../../shared/services/utils';
import { required } from '../../../shared/services/validators';
import { usePermissions } from '../../../v2/features/permissions/hooks.ts';
import { Permission } from '../../../v2/features/permissions/types.ts';
import FormWrapper from '../../FormWrapper';
import { selectIsMetadataBlocked } from '../selectors';

import {
  containerId as additionalInfoContainerId,
  fetchAdditionalInformation as additionalInformationAction,
} from './reducer';
import {
  selectInitialValuesAdditionalInformationForm,
  selectIsFormFetching,
  selectProvidedFormValue,
} from './selectors';

import './styles.scss';

export const PROVIDED_FIELD_NAME = 'additionalInformation.provided';
export const INFORMATION_FIELD_NAME = 'additionalInformation.information';
export const INSURANCE_INTRODUCER_FIELD_NAME = 'insuranceIntroducer';

export const AdditionalInformation = ({
  yesNo,
  insuranceIntroducer,
  fetching,
  isBlocked,
  isProvided,
  fetchAdditionalInformation,
  clearAdditionalInformation,
  change,
  touch,
  untouch,
}) => {
  const intl = useIntl();
  const { id } = useParams();
  const hasExternalReferencePermission = usePermissions(Permission.ExternalReference);
  const hasBadgeNumberPermission = usePermissions(Permission.BadgeNumber);

  useEffect(() => {
    fetchAdditionalInformation(id);

    return () => clearAdditionalInformation(id);
  }, [id, fetchAdditionalInformation, clearAdditionalInformation]);

  const onChangeProvidedField = (e, value) => {
    if (value === SIMPLE_ANSWER.NO) {
      change(INFORMATION_FIELD_NAME, null);
      untouch(INFORMATION_FIELD_NAME);
    }
    if (value === SIMPLE_ANSWER.YES) {
      touch(INFORMATION_FIELD_NAME);
    }
  };

  return (
    <Grid container className="additional-information-details" data-testid="additional-information-component">
      <Grid item xs={9}>
        <AsyncComponent loading={fetching} className="fixed">
          <FormWrapper title="misc.proposal" formName={additionalInfoContainerId}>
            <h2 data-testid="additional-information-page">
              <FormattedMessage id="additionalInformation.title" />
            </h2>
            <hr />
            <div className="radio-button-group">
              <Label
                value={intl.formatMessage({
                  id: 'fields.provided.label',
                })}
                className="inline"
              />
              <FieldWithServerError
                name={PROVIDED_FIELD_NAME}
                component={RadioButtonGroupControl}
                options={yesNo}
                disabled={isBlocked}
                onChange={onChangeProvidedField}
                serverError={{
                  path: PROVIDED_FIELD_NAME,
                }}
                dataTestIdPrefix="provide-additional-information"
              />
            </div>
            {isProvided && (
              <Grid container>
                <Grid item xs={7}>
                  <InfoPanel>
                    <FormattedMessage id="fields.provided.info" />
                  </InfoPanel>
                  <div className="provided-info">
                    <Field
                      name={INFORMATION_FIELD_NAME}
                      component={TextArea}
                      disabled={isBlocked}
                      normalize={additionalInformation.normalize}
                      validate={required}
                      maxLength={1000}
                    />
                  </div>
                </Grid>
              </Grid>
            )}
            <Grid container>
              <Grid item xs={4}>
                <FieldWithServerError
                  name={INSURANCE_INTRODUCER_FIELD_NAME}
                  label={intl.formatMessage({
                    id: 'fields.insuranceIntroducer.label',
                  })}
                  component={DropdownControl}
                  options={insuranceIntroducer}
                  disabled={isBlocked}
                  serverError={{
                    path: INSURANCE_INTRODUCER_FIELD_NAME,
                  }}
                  backspaceRemovesValue={false}
                />
              </Grid>
            </Grid>
            {(hasExternalReferencePermission || hasBadgeNumberPermission) && (
              <>
                <h3>
                  <FormattedMessage id="intermediaryInformation.intermediaryInfo" />
                </h3>
                <Grid container>
                  <Grid item xs={6}>
                    {hasExternalReferencePermission && (
                      <Field
                        name="intermediaryInformation.externalReference"
                        label={intl.formatMessage({
                          id: 'fields.externalReference.label',
                        })}
                        component={TextInputControl}
                        disabled={isBlocked}
                        maxLength={100}
                      />
                    )}
                    {hasBadgeNumberPermission && (
                      <Field
                        name="intermediaryInformation.badgeNumber"
                        label={intl.formatMessage({
                          id: 'fields.badgeNumber.label',
                        })}
                        component={TextInputControl}
                        disabled={isBlocked}
                        maxLength={100}
                      />
                    )}
                  </Grid>
                </Grid>
              </>
            )}
          </FormWrapper>
        </AsyncComponent>
      </Grid>
    </Grid>
  );
};

AdditionalInformation.propTypes = {
  yesNo: yesNoOptionsShape,
  insuranceIntroducer: insuranceIntroducerOptionsShape,
  fetching: bool,
  isBlocked: bool,
  isProvided: bool,
  fetchAdditionalInformation: func,
  clearAdditionalInformation: func,
  change: func,
  untouch: func,
  touch: func,
};

const makeMapStateToProps = (state) => {
  const selectIsProvided = makeSelectIsYes(selectProvidedFormValue);

  return {
    initialValues: selectInitialValuesAdditionalInformationForm(state),
    yesNo: selectYesNo(state),
    insuranceIntroducer: selectInsuranceIntroducer(state),
    fetching: selectIsFormFetching(state),
    isBlocked: selectIsMetadataBlocked(state),
    isProvided: selectIsProvided(state),
  };
};

export const AdditionalInformationForm = reduxForm({
  form: additionalInfoContainerId,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  forceUnregisterOnUnmount: true,
  onSubmitFail: scrollToInvalidInput,
})(AdditionalInformation);

export default connect(makeMapStateToProps, {
  fetchAdditionalInformation: additionalInformationAction.request,
  clearAdditionalInformation: additionalInformationAction.clear,
})(AdditionalInformationForm);
