import get from 'lodash/get';
import some from 'lodash/some';
import { delay } from 'redux-saga';
import {
  all,
  call,
  cancel,
  fork,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import { FIRST_ZONE, SECOND_ZONE } from '../../../shared/components/reduxFormField/DragnDrop';
import {
  DFF_POLLING_INTERVAL,
  DOCUMENT_STATUS,
  PARTY_TYPES,
  POLLING_INTERVAL,
  PROCESS_STATUS,
  SECTIONS,
  SIGNING_METHODS,
} from '../../../shared/constants';
import { api, toastHandler } from '../../../shared/services';
import {
  createTab,
  downloadFile,
  hasMsSaveOrOpenBlobMethod,
  showFile,
} from '../../../shared/services/sagaHelpers';
import {
  hasFailedDocumentStatus,
  hasUnavailableDocumentStatus,
  hasWaitingDocumentStatus,
  scrollToField,
} from '../../../shared/services/utils';
import { setGeneralError } from '../../App/reducer';
import { fetchCompleteness } from '../../NavigationPane/reducer';
import { fetchInsuredAdults } from '../Insured/reducer';
import { selectInsuredAdults } from '../Insured/selectors';
import { fetchPolicyHolder } from '../PolicyHolder/reducer';
import { selectPolicyHolder } from '../PolicyHolder/selectors';
import { fetchMetadata } from '../reducer';
import { handleProposalGeneralError } from '../sagas';
import { selectProposalId } from '../selectors';

import {
  clearDocuments,
  confirmIdentity,
  documentsActions,
  downloadDocument,
  fetchData,
  fetchIdentity,
  fetchSigningMethod,
  pollDocuments,
  requestSigningUrl,
  resetSignerDocuments,
  saveSigningMode,
  sendEmailForDownload,
  setActiveSigner,
  setExternalReference,
  setPollingMessageId,
  setProposalStatus,
  setSavingSigningMode,
  setSigners,
  setSigningError,
  submitSigningMethod,
  uploadFirstSideOfIdCard,
  uploadIdCard,
  uploadSecondSideOfIdCard,
} from './reducer';
import { selectExternalReferenceValue, selectIsAbsentFlow } from './selectors';

export const DOCUMENTS_STATUS_FETCHING_TIMEOUT = 60_000;
export const DOCUMENTS_FETCHING_TIMEOUT = 3.5 * 60 * 1000;
export const DFF_FETCHING_TIMEOUT = 5.5 * 60 * 1000;
export const DOCUMENTS_FAILED_MAP = {
  TIMEOUT: 'TIMEOUT',
};
const pollHandlersMap = {
  [PROCESS_STATUS.WAITING_FOR_IDENTITY_CONFIRMATION]: stopPollDocumentsSaga,
  [PROCESS_STATUS.WAITING_FOR_HEALTH_DECLARATION]: stopPollDocumentsSaga,
  [PROCESS_STATUS.SUBMISSION_FAILED]: stopPollDocumentsSaga,
  [PROCESS_STATUS.SUBMISSION_POSTPONED]: initiatePostponedProcess,
  [PROCESS_STATUS.WAITING_FOR_SIGNATURE]: fetchSignersSaga,
  [PROCESS_STATUS.SIGNING_POSTPONED]: fetchSignersSaga,
  [PROCESS_STATUS.SIGNING_FAILED]: fetchSignersSaga,
  [PROCESS_STATUS.REJECTED_BY_POLICY_PARTY]: fetchSignersSaga,
  [PROCESS_STATUS.IN_REVIEW_BY_DELA]: fetchSignersSaga,
  [PROCESS_STATUS.REVIEW_BY_DELA_FAILED]: fetchSignersSaga,
};
export const firstZoneIdCardName = 'ID_CARD_1';
export const secondZoneIdCardName = 'ID_CARD_2';
export const UPLOAD_PARAM = {
  [FIRST_ZONE]: firstZoneIdCardName,
  [SECOND_ZONE]: secondZoneIdCardName,
};
export const MAX_EXPECTED_POLLING_TIME = 180_000;

export const TECHNICAL_ISSUE_TOAST_ID = 'toasts.warning.signingTechnicalIssue';

const hasPendingDocuments = (signers) => some(signers, (signer) => signer.pendingSignedDocuments);
const hasPostponedDocuments = (signers) => some(signers, (signer) => signer.hasPostponedDocuments);

export function* fetchInitialMetadataSaga(proposalId) {
  yield put(fetchMetadata.request(proposalId));
  const { success: { payload: metadata = {} } = {}, failure: { payload: error } = {} } = yield race(
    {
      success: take(fetchMetadata.SUCCESS),
      failure: take(fetchMetadata.FAILURE),
    }
  );

  if (error || !metadata.blocked) {
    const proposalNotSubmittedError = new Error('Proposal not submitted');
    proposalNotSubmittedError.status = 404;
    throw metadata && !metadata.blocked ? proposalNotSubmittedError : error;
  }
  return metadata;
}

export function* fetchIdentitySaga({ payload: proposalId }) {
  try {
    const data = yield call(api.proposal.fetchIdentity, proposalId);
    yield put(fetchIdentity.success(data));
  } catch (error) {
    yield put(fetchIdentity.failure(error));
    throw error;
  }
}

export function* uploadIdCardSaga({ payload: { files, index, callback } }) {
  const proposalId = yield select(selectProposalId);
  const data = new FormData();
  yield call(setUploadIdCardSagaAction, index, 'request');
  files.forEach((file) => data.append('documents', file));
  if (index) {
    data.append('type', UPLOAD_PARAM[index]);
  }
  try {
    yield call(api.proposal.uploadIdCard, { id: proposalId, file: data });
    yield call(fetchIdentitySaga, { payload: proposalId });
    yield call(setUploadIdCardSagaAction, index, 'success');
  } catch (error) {
    toastHandler.unexpectedIssue();
    yield call(callback, index);
    yield call(setUploadIdCardSagaAction, index, 'failure', error);
  }
}

export function* setUploadIdCardSagaAction(index, actionType, data) {
  let action = null;

  if (index === FIRST_ZONE) {
    action = uploadFirstSideOfIdCard;
  }

  if (index === SECOND_ZONE) {
    action = uploadSecondSideOfIdCard;
  }

  if (action) {
    return yield put(action[actionType](data));
  }

  yield put(uploadFirstSideOfIdCard[actionType](data));
  yield put(uploadSecondSideOfIdCard[actionType](data));
}

export function* confirmIdentitySaga({ payload }) {
  try {
    const proposalId = yield select(selectProposalId);
    yield call(api.proposal.confirmIdentity, proposalId, payload);
    yield call(fetchIdentitySaga, { payload: proposalId });
    yield put(confirmIdentity.success());
    yield put(pollDocuments.request());
  } catch (error) {
    yield put(confirmIdentity.failure(error));
    if (error.status !== 409) {
      yield put(setGeneralError.action(error));
    }
  }
}

export function* fetchDataSaga({ payload: proposalId }) {
  yield put(fetchCompleteness.request({ id: proposalId }));
  try {
    const metadata = yield call(fetchInitialMetadataSaga, proposalId);
    yield put(setProposalStatus.action(metadata.status));
    if (metadata.idCardRequired) {
      yield call(fetchIdentitySaga, { payload: proposalId });
    }
    yield put(pollDocuments.request());
    yield put(fetchData.success());
  } catch (error) {
    yield put(fetchData.failure(error));
    yield call(handleProposalGeneralError, error);
  }
}

export function* pollDocumentsSaga() {
  const proposalId = yield select(selectProposalId);
  const insuredType = yield select(selectExternalReferenceValue);

  const pollWorker = yield fork(pollSagaWorker, proposalId);
  const { timeout } = yield race({
    stop: take([pollDocuments.SUCCESS, pollDocuments.FAILURE, pollDocuments.CLEAR]),
    timeout: delay(insuredType ? DOCUMENTS_STATUS_FETCHING_TIMEOUT : DOCUMENTS_FETCHING_TIMEOUT),
  });
  yield cancel(pollWorker);
  if (timeout) {
    if (insuredType) {
      toastHandler.uniqueError({ id: 'toasts.error.proposal.signatureProcessIssue' });
      yield put(setExternalReference.clear());
      yield call(fetchSignersSaga, proposalId);
    } else {
      yield put(pollDocuments.failure(DOCUMENTS_FAILED_MAP.TIMEOUT));
    }
  }
}

export function* pollSagaWorker(proposalId) {
  yield fork(setPollingMessageSaga);
  try {
    while (true) {
      const status = yield call(api.proposal.getStatus, proposalId);
      yield put(setProposalStatus.action(status));
      const handler = pollHandlersMap[status];
      if (handler) {
        yield call(handler, proposalId);
      }
      yield call(delay, POLLING_INTERVAL);
    }
  } catch (error) {
    yield put(pollDocuments.failure(error));
    yield put(setGeneralError.action(error));
  }
}

export function* setPollingMessageSaga() {
  yield put(setPollingMessageId.action('documents.mightTakeTime'));
  yield call(delay, MAX_EXPECTED_POLLING_TIME);
  yield put(setPollingMessageId.action('documents.takeMoreTime'));
}

export function* stopPollDocumentsSaga() {
  yield put(pollDocuments.success());
}

export function* fetchSigningMethodSaga(proposalId) {
  try {
    yield put(fetchSigningMethod.request());
    const data = yield call(api.proposal.getSigningMethod, proposalId);
    yield put(fetchSigningMethod.success(data));
    yield put(pollDocuments.success());
  } catch (error) {
    yield put(fetchSigningMethod.failure(error));
    yield put(setGeneralError.action(error));
  }
}

export function* submitSigningMethodSaga({ payload }) {
  const proposalId = yield select(selectProposalId);
  try {
    yield call(api.proposal.saveSigningMethod, proposalId, payload);
    yield call(fetchSigningMethodSaga, proposalId);
    yield put(submitSigningMethod.success());
    toastHandler.success({ id: 'toasts.success.signingMethodFormSaved' });
  } catch (error) {
    yield put(submitSigningMethod.failure(error));
    toastHandler.unexpectedIssue();
  }
}

export function* fetchSignersSaga(proposalId) {
  const insuredType = yield select(selectExternalReferenceValue);

  yield put(fetchMetadata.request(proposalId));
  try {
    const { content: signers = {} } = yield call(api.proposal.getSigners, proposalId);

    if (insuredType && signers[insuredType].status === DOCUMENT_STATUS.NOT_SIGNED) {
      return;
    }
    if (hasPendingDocuments(signers)) {
      return;
    }
    if (hasPostponedDocuments(signers) || hasWaitingDocumentStatus(signers)) {
      yield put(setSigningError.action(true));
      toastHandler.uniqueWarning({ id: TECHNICAL_ISSUE_TOAST_ID });
    }
    if (hasPostponedDocuments(signers)) {
      yield call(fetchSigningMethodSaga, proposalId);
    }
    if (hasUnavailableDocumentStatus(signers) || hasFailedDocumentStatus(signers)) {
      toastHandler.uniqueWarning({ id: TECHNICAL_ISSUE_TOAST_ID });
    }

    yield put(fetchCompleteness.request({ id: proposalId }));
    yield put(setSigners.action(signers));
    yield put(pollDocuments.success());
  } catch (error) {
    yield put(pollDocuments.failure(error));
    yield put(setGeneralError.action(error));
  }
}

export const createRequestSigningUrlSaga = (scrollToFieldHandler) =>
  function* ({ payload: documentSigner }) {
    const proposalId = yield select(selectProposalId);
    try {
      yield put(setActiveSigner.action(documentSigner));
      const { signingUrl } = yield call(api.proposal.requestSigningUrl, proposalId, {
        documentSigner,
      });
      if (!signingUrl) {
        throw new Error('URL not found');
      }
      yield put(requestSigningUrl.success());

      // we need to put setActiveSigner.clear() action if redirect to connective is removed
      window.location.assign(signingUrl);
    } catch (error) {
      yield put(requestSigningUrl.failure(error));
      yield put(setActiveSigner.clear());

      if (error.status === 403) {
        yield put(pollDocuments.request());
        return;
      }
      if (error.status === 409 || error.status === 504) {
        yield put(setSigningError.action(true));
        toastHandler.uniqueWarning({ id: TECHNICAL_ISSUE_TOAST_ID });
        scrollToFieldHandler('signingMethod');
        const isAbsentFlow = yield select(selectIsAbsentFlow);
        if (!isAbsentFlow) {
          yield call(fetchSigningMethodSaga, proposalId);
        }
        return;
      }
      yield put(setGeneralError.action(error));
    }
  };

export const requestSigningUrlSaga = createRequestSigningUrlSaga(scrollToField);

export function* resetSignerDocumentsSaga({ payload: documentSigner }) {
  const proposalId = yield select(selectProposalId);
  try {
    yield call(api.proposal.resetSignerDocuments, proposalId, {
      documentSigner,
    });
    yield put(resetSignerDocuments.success());
    yield put(pollDocuments.request());
    yield put(setSigners.clear());
  } catch (error) {
    yield put(resetSignerDocuments.failure(error));
    toastHandler.unexpectedIssue();
  }
}

export function* handleSendDocumentByEmailSaga({ endpoint, action, payload, messageId }) {
  const { documentSigner } = payload;
  const proposalId = yield select(selectProposalId);

  try {
    yield put(setSavingSigningMode.action(documentSigner));

    yield call(endpoint, proposalId, payload);

    const { content: signers = {} } = yield call(api.proposal.getSigners, proposalId);
    yield put(setSigners.action(signers));
    yield put(action.success());

    if (messageId) {
      toastHandler.uniqueSuccess({ id: messageId });
    }

    yield put(setSavingSigningMode.clear(documentSigner));
  } catch (error) {
    if (error.status === 409) {
      const { content: signers = {} } = yield call(api.proposal.getSigners, proposalId);
      yield put(setSigners.action(signers));
      yield put(action.success());
    } else {
      yield put(action.failure(error));
      toastHandler.unexpectedIssue();
    }
    yield put(setSavingSigningMode.clear(documentSigner));
  }
}

export function* saveSigningModeSaga({ payload }) {
  const { documentSigner, signingMode, email } = payload;

  const messageId =
    signingMode === SIGNING_METHODS.REMOTELY && 'toasts.success.resignRemotelyMethodSaved';
  const endpoint = api.proposal.saveSigningMode;

  yield call(handleSendDocumentByEmailSaga, {
    endpoint,
    action: saveSigningMode,
    payload,
    messageId,
  });

  if (signingMode === SIGNING_METHODS.WITH_INTERMEDIARY) {
    yield put(requestSigningUrl.request(documentSigner));
  }
  if (!email) {
    return;
  }

  if (documentSigner === PARTY_TYPES.POLICY_HOLDER) {
    const policyHolder = yield select(selectPolicyHolder);
    yield put(
      fetchPolicyHolder.update({
        ...policyHolder,
        contactInformation: {
          ...policyHolder.contactInformation,
          email,
        },
      })
    );
    return;
  }

  const insuredAdults = yield select(selectInsuredAdults);
  const firstInsuredAdult = get(insuredAdults, SECTIONS.FIRST_INSURED_ADULT);
  const secondInsuredAdult = get(insuredAdults, SECTIONS.SECOND_INSURED_ADULT);

  if (documentSigner === PARTY_TYPES.FIRST_INSURED_ADULT) {
    yield put(fetchInsuredAdults.update([{ ...firstInsuredAdult, email }, secondInsuredAdult]));
    return;
  }

  if (documentSigner === PARTY_TYPES.SECOND_INSURED_ADULT) {
    yield put(fetchInsuredAdults.update([firstInsuredAdult, { ...secondInsuredAdult, email }]));
  }
}

export function* sendEmailForDownloadSaga({ payload }) {
  const messageId = 'toasts.success.resignRemotelyMethodSaved';
  const endpoint = api.proposal.sendSignedDocuments;

  yield call(handleSendDocumentByEmailSaga, {
    endpoint,
    action: sendEmailForDownload,
    payload,
    messageId,
  });
}

export function* pollDocumentsSignersSaga() {
  const pollWorker = yield fork(pollDocumentsSagaWorker);
  yield race({
    stop: take(setSigningError.CLEAR),
    timeout: delay(DFF_FETCHING_TIMEOUT),
  });
  yield cancel(pollWorker);
}

export function* pollDocumentsSagaWorker() {
  try {
    while (true) {
      const proposalId = yield select(selectProposalId);
      const { content: signers = {} } = yield call(api.proposal.getSigners, proposalId);

      yield put(setSigners.action(signers));

      if (!hasPostponedDocuments(signers) && !hasWaitingDocumentStatus(signers)) {
        yield put(fetchSigningMethod.clear());
        yield put(setSigningError.clear());
        return;
      }

      yield call(delay, DFF_POLLING_INTERVAL);
    }
  } catch (error) {
    yield put(setGeneralError.action(error));
  }
}

export function* previewDocumentSaga(documentId, documentName) {
  const proposalId = yield select(selectProposalId);
  const tab = !hasMsSaveOrOpenBlobMethod() && (yield call(createTab));
  const docName = `${documentName}.pdf`;
  try {
    const response = yield call(api.proposal.downloadDocument, proposalId, documentId);
    const newBlob = new Blob([response], { type: 'application/pdf' });
    showFile(newBlob, tab, docName);
  } catch (error) {
    yield put(downloadDocument.failure(error));
    toastHandler.unexpectedIssue();
  }
}

export function* downloadDocumentSaga({ payload: { documentId, documentName, preview } }) {
  try {
    if (preview) {
      yield call(previewDocumentSaga, documentId, documentName);
    } else {
      const proposalId = yield select(selectProposalId);
      const docName = `${documentName}.pdf`;
      const response = yield call(api.proposal.downloadDocument, proposalId, documentId);
      downloadFile(response, docName);
    }
    yield put(downloadDocument.success());
  } catch (error) {
    yield put(downloadDocument.failure(error));
    toastHandler.unexpectedIssue();
  }
}

export function* initiatePostponedProcess(proposalId) {
  toastHandler.uniqueWarning({ id: 'toasts.warning.documentsTechnicalIssue' });
  yield call(fetchSigningMethodSaga, proposalId);
}

export function* clearDocumentsSaga() {
  for (const action of documentsActions) {
    yield put(action.clear());
  }
}

export default function* documentsSagas() {
  yield all([
    takeLatest(fetchData.REQUEST, fetchDataSaga),
    takeLatest(fetchIdentity.REQUEST, fetchIdentitySaga),
    takeEvery(uploadIdCard.ACTION, uploadIdCardSaga),
    takeEvery(confirmIdentity.REQUEST, confirmIdentitySaga),
    takeLatest(pollDocuments.REQUEST, pollDocumentsSaga),
    takeLatest(submitSigningMethod.REQUEST, submitSigningMethodSaga),
    takeLatest(saveSigningMode.REQUEST, saveSigningModeSaga),
    takeLatest(requestSigningUrl.REQUEST, requestSigningUrlSaga),
    takeLatest(resetSignerDocuments.REQUEST, resetSignerDocumentsSaga),
    takeEvery(downloadDocument.REQUEST, downloadDocumentSaga),
    takeLatest(clearDocuments.ACTION, clearDocumentsSaga),
    takeLatest(setSigningError.ACTION, pollDocumentsSignersSaga),
    takeLatest(sendEmailForDownload.REQUEST, sendEmailForDownloadSaga),
  ]);
}
