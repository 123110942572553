import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';

export const AddInsuredAdultButton = ({onClick, children, dataTestIdPrefix}) => {
   return (
      <button type="button" className="add-insured-adult" data-testid={`add-${dataTestIdPrefix}-button`} onClick={onClick}>
         <div className="icon-container">
            <i className="iconfont icon-person" />
         </div>
         {children}
      </button>
   );
};

AddInsuredAdultButton.propTypes = {
   onClick: PropTypes.func,
   children: PropTypes.node,
};
