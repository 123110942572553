import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio, {RadioProps} from '@mui/material/Radio';
import RadioGroup, {RadioGroupProps} from '@mui/material/RadioGroup';
import React from 'react';
import Label from 'v2/common/components/Label';

export type RadioGroupOption = Pick<RadioProps, 'disabled'> & {
   label: string;
   value: string;
};

type RadioGroupFieldProps = RadioGroupProps & {
   label?: React.ReactNode;
   hint?: React.ReactNode;
   error?: boolean;
   options: RadioGroupOption[];
   dataTestIdPrefix?: string;
};

const RadioGroupField = React.forwardRef<any, RadioGroupFieldProps>(
   ({label, hint, error = false, options, dataTestIdPrefix, ...radioGroupProps}, ref) => {
      return (
         <FormControl ref={ref} error={error} margin="normal">
            {label && (
               <FormLabel>
                  <Label label={label} hint={hint} />
               </FormLabel>
            )}
            <RadioGroup {...radioGroupProps}>
               {options.map((option) => (
                  <FormControlLabel
                     key={option.value}
                     control={<Radio />}
                     value={option.value}
                     label={option.label}
                     disabled={option.disabled}
                     data-testid={`${dataTestIdPrefix}-${option.value?.toLowerCase()}-radio`}
                  />
               ))}
            </RadioGroup>
         </FormControl>
      );
   }
);

export default RadioGroupField;
